import React, { useEffect, useState } from 'react';
import useApiError from '../../../../hooks/useApiError';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import CryptoJS from 'crypto-js';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import TbSelect from '../../../../components/common/ui/form/TbSelect';
import TbInput from '../../../../components/common/ui/form/TbInput';
import APIClient from '../../../../api/APIClient';

interface ResLogoProduct {
  id: number;
  name: string;
  amount: number;
  price: number;
}
// TODO 임시로 API 서버에서 요청해서 불러옴.(추후 DB로 전환 예정)
const getLogoProductList = async (): Promise<ResLogoProduct[]> => {
  const response = await APIClient.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscribe/pay-logo/list`);
  return response.data;
};

interface CreateLogoPayURL {
  idProduct: number | null;
  optionAmount?: number | null;
  optionMessage?: string | null;
}
const PayUrlLogo = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [listProduct, setListProduct] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<ResLogoProduct>();

  // Form
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateLogoPayURL>();
  // 모든 필드의 값을 감시하고 싶은 경우
  const fromData = watch();

  /**
   * 로고 상품 목록 조회
   * */
  const { isLoading } = useQuery([], () => getLogoProductList(), {
    onSuccess: (data) => {
      if (data) {
        setListProduct(
          data.map((item: any): { label: string; value: number; data: ResLogoProduct } => {
            return { label: item.name, value: item.id, data: item };
          }),
        );
      }
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 서브밋
   * */
  const onSubmit = (data: CreateLogoPayURL) => {
    let resultURL = `${process.env.REACT_APP_SERVICE_URL}/brand/setting/paylogo/${data.idProduct}`;

    // 추가 금액이 있거나 추가 옵션 설명이 있는 경우.
    if ((data.optionAmount && Number(data.optionAmount) > 0) || data.optionMessage) {
      const strJSONData = JSON.stringify({
        optionAmount: data.optionAmount && Number(data.optionAmount) > 0 ? Number(data.optionAmount) : 0,
        optionMessage: data.optionMessage,
      });

      // url 위변조 방지를 위한 암호화 인코딩.
      const secretKey = 'D$dId9d1%fdD';
      const encryptedData = CryptoJS.AES.encrypt(strJSONData, secretKey).toString();
      const encodedData = encodeURIComponent(encryptedData); // URL 안전하게 처리
      resultURL = resultURL + `?d=${encodedData}`;
    }
    if (resultURL && confirm(`로고 결제 URL이 생성되었습니다. \n\n ${resultURL}\n\n 클립보드에 복사할까요?`)) {
      // 클립보드에 저장
      copyToClipboard(resultURL);
    }
    reset({
      idProduct: null,
      optionAmount: null,
      optionMessage: '',
    });
  };

  /**
   * 클립보드 저장
   * */
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('결제 URL이 복사되었습니다.');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // 선택 요금제 state에 저장.
  useEffect(() => {
    listProduct.some((item: any) => {
      if (Number(item.value) === Number(fromData.idProduct)) {
        return setSelectedProduct(item.data);
      }
    });
  }, [fromData.idProduct]);

  return (
    <>
      <Card>
        <CardHeader>
          <h5>로고 결제 URL 생성</h5>
        </CardHeader>
        <CardBody>
          <p>
            로고 상품 결제를 위한 링크를 생성합니다.
            <br />
            <span className={'text-danger'}>(*회원 가입 필수, 결제 시 Basic 12개월 구독이 포함됩니다.)</span>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TbSelect
              label={'로고 상품'}
              name={'idProduct'}
              options={listProduct}
              horizontal={true}
              register={register}
              errors={errors}
              validation={{ required: true }}
            />
            <TbInput
              label={'추가 옵션 금액'}
              name={'optionAmount'}
              type="number"
              horizontal={true}
              register={register}
              errors={errors}
            />
            <TbInput
              label={'추가 옵션 설명'}
              name={'optionMessage'}
              type="text"
              horizontal={true}
              register={register}
              errors={errors}
            />
            <hr />
            <div className={'d-flex mb-3'}>
              <div style={{ flexShrink: 0, width: 100 }}>로고 상품</div>
              <div className={'h5'}>{selectedProduct?.name}</div>
            </div>
            <div className={'d-flex mb-3'}>
              <div style={{ flexShrink: 0, width: 100 }}>총 결제 금액</div>
              <div className={'h5'}>
                {Number(
                  (selectedProduct?.amount ? selectedProduct.amount : 0) + Number(fromData?.optionAmount),
                ).toLocaleString()}{' '}
                원
              </div>
            </div>
            <div className={'d-flex mb-3'}>
              <div style={{ flexShrink: 0, width: 100 }}>옵션 상세</div>
              <div className={'h5'}>{fromData?.optionMessage}</div>
            </div>
            <div className={'text-end'}>
              <Button color="primary" type="submit" disabled={isLoading}>
                결제 URL 생성하기
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};
export default React.memo(PayUrlLogo);
