import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import TbInput from '../../../../components/common/ui/form/TbInput';
import { useForm } from 'react-hook-form';
import useApiError from '../../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { autoGenerate, IdeogramStyleType, ReqIdeogramAutoGen } from '../../../../api/thirdPartyAPI';
import { toast } from 'react-toastify';
import TbLoading from '../../../../components/common/ui/TbLoading';
import TbTextarea from '../../../../components/common/ui/form/TbTextarea';
import TbSelect from '../../../../components/common/ui/form/TbSelect';

const ThirdPartyPage = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const autoGenerateMutation = useMutation(autoGenerate);
  const [listFile, setListFile] = useState([]);
  const [listStyleType] = useState<any>([
    { label: 'Auto', value: IdeogramStyleType.AUTO },
    { label: 'General', value: IdeogramStyleType.GENERAL },
    { label: 'Realistic', value: IdeogramStyleType.REALISTIC },
    { label: 'Design', value: IdeogramStyleType.DESIGN },
    { label: 'Render_3D', value: IdeogramStyleType.RENDER_3D },
    { label: 'Anime', value: IdeogramStyleType.ANIME },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  // const [imageWeight, setImageWeight] = useState<number>(50);
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ReqIdeogramAutoGen>({
    defaultValues: {
      request_image: {
        repeat: 4,
        style_type: IdeogramStyleType.AUTO,
        image_weight: 50,
      },
    },
  });

  /**
   * 이미지 생성 요청.
   * */
  const onSubmit = async (data: ReqIdeogramAutoGen) => {
    try {
      setIsLoading(true);
      const resultData: any = await autoGenerateMutation.mutateAsync(data);
      if (resultData && resultData.message) {
        if (resultData && resultData.result) {
          toast.success(resultData.message);
          if (resultData.data?.list && resultData.data.list.length > 0) {
            setListFile(resultData.data.list);
            reset({
              path: '',
            });
          }
        } else {
          toast.error(resultData.message);
        }
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="도구 모음" title="Ideogram API">
        <div className="mb-0">
          {/*<Button color="primary" className="me-3" type="submit">*/}
          {/*  저장*/}
          {/*</Button>*/}
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="8">
            <Card>
              <div className="card-header">
                <h5>이미지 자동 생성 도구</h5>
              </div>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={5}>
                      <Row>
                        <Col sm={12}>
                          <TbInput
                            label={'참조 폴더명'}
                            message={"'NAS > gen_images > ref' 경로의 참조 폴더명을 입력하세요."}
                            name={'path'}
                            placeholder={'참조 이미지 폴더 경로'}
                            type="text"
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbSelect
                            label={'이미지 타입'}
                            name={'request_image.style_type'}
                            options={listStyleType}
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbInput
                            label={'이미지 비중'}
                            message={'참조 이미지 반영 비율(0~100%)'}
                            name={'request_image.image_weight'}
                            type="number"
                            min={0}
                            max={100}
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbSelect
                            label={'반복 횟수'}
                            name={'request_image.repeat'}
                            options={[
                              { label: '1회', value: 1 },
                              { label: '2회', value: 2 },
                              { label: '3회', value: 3 },
                              { label: '4회', value: 4 },
                            ]}
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbTextarea
                            label="커스텀 Prompt"
                            id="request_image.prompt"
                            name={'request_image.prompt'}
                            rows={12}
                            placeholder="미 입력 시 자동 생성된 Prompt가 적용됩니다."
                            register={register}
                            errors={errors}
                          ></TbTextarea>
                        </Col>
                      </Row>
                      <div className={'text-end'}>
                        <Button color="primary" className="me-3" type="submit" disabled={isLoading}>
                          이미지 생성
                        </Button>
                      </div>
                      <hr />
                      {isLoading ? (
                        <TbLoading />
                      ) : (
                        <ul>
                          {listFile.map((file) => (
                            <li key={file}>{file}</li>
                          ))}
                          {listFile.length === 0 && <li className={'text-danger'}>참조 이미지가 없습니다.</li>}
                        </ul>
                      )}
                    </Col>
                    <Col md={7}>
                      <h6 className={'text-danger'}>[주의사항]</h6>
                      <ol>
                        <li>
                          재시도 하는 경우 필히 새로운 폴더를 생성해 주세요.
                          <br />
                          (동일 폴더로 2회 이상 요청시 중복 요청 될 수 있습니다.)
                        </li>
                      </ol>
                      <br />
                      <h6>[생성 방법]</h6>
                      <ol className={'d-block'}>
                        <li className={'mb-2'}>
                          <b>'Nas&#62;gen_images&#62;ref'</b> 경로에 구분을 위한 폴더 생성 <br />
                          예시) 날짜 + 이름 : 20241010_flower
                        </li>
                        <li className={'mb-2'}>
                          생성한 폴더에 참조 대상 이미지를 추가
                          <br />
                          <span className={'text-danger'}>(이미지 생성시 참조된 이미지는 자동 삭제됩니다.)</span>
                        </li>
                        <li className={'mb-2'}>
                          <b>'이미지 자동 생성 도구'</b>의 <b>'참조 폴더명'</b> 인풋에 <b>'절차 1'</b>에서 생성한 폴더
                          명을 입력
                        </li>
                        <li className={'mb-2'}>
                          <b>(선택 옵션)</b> 참조 이미지 반영 비율 조정(기본값 50%)
                        </li>
                        <li className={'mb-2'}>
                          <b>(선택 옵션)</b>) 커스텀 Prompt 입력
                          <br />
                          <span className={'text-danger'}>
                            (입력된 Prompt가 우선 적용 되며, 미 입력시 자동 생성된 Prompt가 적용됩니다.)
                          </span>
                        </li>
                        <li className={'mb-2'}>
                          <b>'이미지 생성'</b>버튼 클릭
                        </li>
                        <li className={'mb-2'}>
                          'Nas&#62;gen_images&#62;ai&#62;<b className={'text-danger'}>[생성한 참조 폴더]</b>'경로에서
                          생성된 이미지 확인
                          <br />
                          <span className={'text-danger'}>(생성된 이미지는 최대 1분 간격으로 갱신됩니다.)</span>
                        </li>
                      </ol>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(ThirdPartyPage);
