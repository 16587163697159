import React from 'react';

// WorkFlow
import Workflow from '../pages/workflow/list';
import ReadWorkflow from '../pages/workflow/read';
import CreateWorkflow from '../pages/workflow/update';
// Subscription
import ListSubscription from '../pages/subscription/list';
import ReadSubscription from '../pages/subscription/read';
// Payment
import ReadPayment from '../pages/payment/read';
import PaymentList from '../pages/payment/list';
// Credit
import ListCredit from '../pages/credit/list';
import UpdateCredit from '../pages/credit/update';
// Task
import ListTask from '../pages/task/list';
import UpdateTask from '../pages/task/update';
import CreateTask from '../pages/task/create';
import ProductCategoryTask from '../pages/task/product/category/list';
import UpdateProductCategoryTask from '../pages/task/product/category/update';
import ProductTask from '../pages/task/product/list';
import UpdateProductTask from '../pages/task/product/update';
// Contact
import ListContact from '../pages/contact/list';
import UpdateContact from '../pages/contact/update';
// Industry
import Industry from '../pages/industry/list';
import UpdateIndustry from '../pages/industry/update';
import CategoryIndustry from '../pages/industry/category/list';
import UpdateCategoryIndustry from '../pages/industry/category/update';
// Keyword
import Keyword from '../pages/keyword/list';
import UpdateKeyword from '../pages/keyword/update';
// Settings
import Settings from '../pages/setting/index';
import Thirdparty from '../pages/tools/thirdparty/ideogram';
import PayUrlLogoPage from '../pages/tools/payurl/logo';
import PayUrlUniversalPage from '../pages/tools/payurl/universal';
import WorkflowLogoPage from '../pages/workflow/logo';
// Editor
import EditorElementListPage from '../pages/editor-element/list';
import EditorElementUpdate from '../pages/editor-element/update';
import EditorElementCollectionList from '../pages/editor-element/collectionList';
import EditorElementCategoryUpdate from '../pages/editor-element/categoryUpdate';
import EditorElementCategoryList from '../pages/editor-element/categoryList';
import EditorElementCollectionUpdate from '../pages/editor-element/collectionUpdate';
import EditorElementBatch from '../pages/editor-element/batch';
import LogoConverter from '../pages/tools/logo-converter';

export const routes = [
  { path: `/admin`, Component: <Workflow /> },
  // WorkFlow
  { path: `/admin/workflow/logo`, Component: <WorkflowLogoPage /> },
  { path: `/admin/workflow/list`, Component: <Workflow /> },
  { path: `/admin/workflow/update`, Component: <CreateWorkflow /> },
  { path: `/admin/workflow/update/:id`, Component: <CreateWorkflow /> },
  { path: `/admin/workflow/read/:id`, Component: <ReadWorkflow /> },
  // Subscription
  { path: `/admin/subscription/list`, Component: <ListSubscription /> },
  { path: `/admin/subscription/read/:id`, Component: <ReadSubscription /> },
  // Payment
  { path: `/admin/payment/list`, Component: <PaymentList /> },
  { path: `/admin/payment/read/:id`, Component: <ReadPayment /> },
  // Credit
  { path: `/admin/credit/list`, Component: <ListCredit /> },
  { path: `/admin/credit/update/:id`, Component: <UpdateCredit /> },
  // Task
  { path: `/admin/task/list`, Component: <ListTask /> },
  { path: `/admin/task/create`, Component: <CreateTask /> },
  { path: `/admin/task/update/:id`, Component: <UpdateTask /> },
  { path: `/admin/task/product/list`, Component: <ProductTask /> },
  { path: `/admin/task/product/update`, Component: <UpdateProductTask /> },
  { path: `/admin/task/product/update/:id`, Component: <UpdateProductTask /> },
  { path: `/admin/task/product/category/list`, Component: <ProductCategoryTask /> },
  { path: `/admin/task/product/category/update`, Component: <UpdateProductCategoryTask /> },
  { path: `/admin/task/product/category/update/:id`, Component: <UpdateProductCategoryTask /> },
  // Contact
  { path: `/admin/contact/list`, Component: <ListContact /> },
  { path: `/admin/contact/update/:id`, Component: <UpdateContact /> },
  // Industry
  { path: `/admin/industry/list`, Component: <Industry /> },
  { path: `/admin/industry/update`, Component: <UpdateIndustry /> },
  { path: `/admin/industry/update/:id`, Component: <UpdateIndustry /> },
  // Industry - Category
  { path: `/admin/industry/category/list`, Component: <CategoryIndustry /> },
  { path: `/admin/industry/category/update`, Component: <UpdateCategoryIndustry /> },
  { path: `/admin/industry/category/update/:id`, Component: <UpdateCategoryIndustry /> },
  // Keyword
  { path: `/admin/keyword/list`, Component: <Keyword /> },
  { path: `/admin/keyword/update`, Component: <UpdateKeyword /> },
  { path: `/admin/keyword/update/:id`, Component: <UpdateKeyword /> },
  // Editor = Element
  { path: `/admin/editor-element/category/list`, Component: <EditorElementCategoryList /> },
  { path: `/admin/editor-element/category/update/:id`, Component: <EditorElementCategoryUpdate /> },
  { path: `/admin/editor-element/category/create`, Component: <EditorElementCategoryUpdate /> },
  { path: `/admin/editor-element/collection/list`, Component: <EditorElementCollectionList /> },
  { path: `/admin/editor-element/collection/update/:id`, Component: <EditorElementCollectionUpdate /> },
  { path: `/admin/editor-element/collection/create`, Component: <EditorElementCollectionUpdate /> },
  { path: `/admin/editor-element/list`, Component: <EditorElementListPage /> },
  { path: `/admin/editor-element/create`, Component: <EditorElementUpdate /> },
  { path: `/admin/editor-element/update/:id`, Component: <EditorElementUpdate /> },
  { path: `/admin/editor-element/batch`, Component: <EditorElementBatch /> },
  // Tools - ThirdParty
  { path: `/admin/tools/third-party/ideogram`, Component: <Thirdparty /> },
  // Tools - PayUrl
  { path: `/admin/tools/pay-url/logo`, Component: <PayUrlLogoPage /> },
  { path: `/admin/tools/pay-url/universal`, Component: <PayUrlUniversalPage /> },
  // Tools - PayUrl
  { path: `/admin/tools/logo-converter`, Component: <LogoConverter /> },
  // Settings
  { path: `/admin/setting`, Component: <Settings /> },
];
