import React from 'react';
export const editorElementColumns = () => [
  {
    name: 'ID',
    selector: (row: any) => row.idElement,
    center: true,
    width: '60px',
  },
  {
    name: '미리보기',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <img
              src={row.thumbnail}
              alt={'요소'}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '100px',
                maxHeight: '70px',
              }}
            />
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '100px',
  },
  {
    name: '타입',
    selector: (row: any) => row.type,
    center: true,
    width: '80px',
  },
  {
    name: '카테고리',
    cell: (row: any) => {
      return <div>{row.categories?.map((item: any) => item.name).join(', ')}</div>;
    },
    center: true,
    width: '100px',
  },
  {
    name: '컬렉션',
    cell: (row: any) => {
      return <div>{row.collections?.map((item: any) => item.name).join(', ')}</div>;
    },
    center: true,
    width: '200px',
  },
  {
    name: '태그',
    cell: (row: any) => {
      return (
        <div>
          {row.tag?.map((tag: string) => (
            <span key={tag} className={'btn btn-light btn-xs m-r-5'}>
              {tag}
            </span>
          ))}
        </div>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
];
