import APIClient from './APIClient';

/**
 * [리소스 버킷] 업로드 파일
 * */
export const resourceUploadFile = async (file: File) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  const response = await APIClient.post(`/v2/api/file/upload`, formData, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/**
 * [리소스 버킷] 업로드 이미지
 * */
export const resourceUploadImage = async (file: File) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  const response = await APIClient.post(`/v2/api/file/upload-image`, formData, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/**
 * [리소스 파일] 파일 삭제
 * */
export const resourceDeleteFile = async (url: string) => {
  const response = await APIClient.delete(`/v2/api/file/delete?url=${url}`);
  return response.data;
};

/**
 * TODO [스토리지 버킷] 업로드 파일
 * */
