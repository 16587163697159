import APIClient from './APIClient';

export interface ReqIdeogramAutoGen {
  path: string;
  request_image: ReqIdeogramImage;
}

export enum IdeogramStyleType {
  AUTO = 'AUTO',
  GENERAL = 'GENERAL',
  REALISTIC = 'REALISTIC',
  DESIGN = 'DESIGN',
  RENDER_3D = 'RENDER_3D',
  ANIME = 'ANIME',
}
export interface ReqIdeogramImage {
  repeat: number;
  prompt: string;
  // aspect_ratio: string;
  // magic_prompt_option: string;
  // model: string;
  image_weight: number;
  // seed: number;
  style_type: IdeogramStyleType;
  // negative_prompt: number;
  // resolution: number;
  // color_palette: number;
}

export const autoGenerate = async (reqBody: ReqIdeogramAutoGen) => {
  const response = await APIClient.post(
    `/v2/api/third-party/ideogram/auto-generate/${reqBody.path}`,
    reqBody.request_image,
  );
  return response.data;
};
